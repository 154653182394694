// This file is generated by Slice Machine. Update with care!
import dynamic from 'next/dynamic';

// We disable server side rendering for comparison_table to call getUrlWithTracker and access the window object in the Public LP comparison table
// https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#with-no-ssr

export const components = {
  addon_section: dynamic(() => import('./AddonSection')),
  calculator: dynamic(() => import('./Calculator')),
  comparison_table: dynamic(() => import('./ComparisonTable'), {
    ssr: false,
  }),
  cta_banner: dynamic(() => import('./CtaBanner')),
  faq_section: dynamic(() => import('./FaqSection')),
  hero_banner: dynamic(() => import('./HeroBanner')),
  markdown: dynamic(() => import('./Markdown')),
  info_card_section: dynamic(() => import('./InfoCardSection')),
  policy_link_section: dynamic(() => import('./PolicyLinkSection')),
  private_quote_widget: dynamic(() => import('./PrivateQuoteWidget')),
  review_carousel: dynamic(() => import('./ReviewCarousel')),
  section_header: dynamic(() => import('./SectionHeader')),
  table: dynamic(() => import('./Table')),
  text_block: dynamic(() => import('./TextBlock')),
  text_modal: dynamic(() => import('./TextModal')),
  text_with_image: dynamic(() => import('./TextWithImage')),
  text_with_video: dynamic(() => import('./TextWithVideo')),
  vertical_padding: dynamic(() => import('./VerticalPadding')),
};
