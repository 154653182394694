import { SliceZone } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import { captureException } from '@sentry/nextjs';
import { AnnouncementBar } from 'components/AnnoucementBar';
import MissingRequiredFields from 'components/MissingRequiredFields';
import { featherAggregatedRating } from 'components/reviewBadge';
import { ENGLISH_LOCALE_IDENTIFIER, LOCALES } from 'constants/i18n';
import { PRISMIC_EXCLUDE_FROM_SITEMAP_TAG } from 'constants/prismic';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo, ProductJsonLd } from 'next-seo';
import { AnnouncementBarDocument } from 'types.generated';
import { getNamespacesFromLocale } from 'util/getNamespacesFromLocale';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';
import { isProduction } from 'util/isProduction';
import {
  getPathSegmentsFromPageDoc,
  getUidFromPathSegments,
} from 'util/prismicPathResolver';

import { createPrismicClient } from '../prismicio';
import { components } from '../slices';
import styles from './style.module.scss';

const VerticalLandingPage = ({
  page,
  navOffset,
  announcementBar,
}: {
  page: PrismicDocument;
  navOffset: number;
  announcementBar: AnnouncementBarDocument;
}) => {
  const {
    seo_title: seoTitle,
    seo_description: seoDescription,
    og_description: ogDescription,
    og_image: ogImage,
    product_name: productName,
    aggregate_offer_low_price: aggregateOfferLowPrice,
    slices,
  } = page.data;

  if (!seoTitle || !seoDescription) {
    return (
      <div className={`pt64 ${styles.container}`}>
        <MissingRequiredFields />;
      </div>
    );
  }

  const hasOgImage = Object.keys(ogImage).length !== 0;
  const isExcludedFromSiteMap = page.tags.includes(
    PRISMIC_EXCLUDE_FROM_SITEMAP_TAG
  );

  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          description: ogDescription,
          images: hasOgImage
            ? [
                {
                  url: ogImage.url,
                  width: ogImage.width,
                  height: ogImage.height,
                  alt: ogImage.alt,
                },
              ]
            : [],
        }}
        noindex={isExcludedFromSiteMap && isProduction}
      />
      {productName && aggregateOfferLowPrice && (
        <ProductJsonLd
          productName={productName}
          description={ogDescription}
          aggregateRating={featherAggregatedRating}
          brand="Feather"
          aggregateOffer={{
            lowPrice: aggregateOfferLowPrice,
            priceCurrency: 'EUR',
          }}
          images={[ogImage.url]}
        />
      )}
      <div className={`pt64 ${styles.container}`}>
        {announcementBar && <AnnouncementBar document={announcementBar} />}
        <SliceZone
          slices={slices}
          components={components as never}
          context={{ navOffset }}
        />
      </div>
    </>
  );
};

export default VerticalLandingPage;

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  previewData,
}) => {
  // params.uid should be an array segmented by '/': https://nextjs.org/docs/pages/building-your-application/routing/dynamic-routes#catch-all-segments
  if (!params?.uid || typeof params.uid === 'string') return { notFound: true };
  const { uid: uidArr } = params;
  const client = createPrismicClient({ previewData });

  const page = await client.getByUID('page', getUidFromPathSegments(uidArr), {
    lang: locale,
    fetchLinks: [
      'comparisontable.uid',
      'comparisontable.name',
      'comparisontable.collapsible_sections',
      'comparisontable.hide_details',
      'comparisontable.slices',
    ],
  });

  let announcementBar: AnnouncementBarDocument | null = null;

  try {
    announcementBar = await client.getByUID('announcement_bar', page.uid, {
      lang: locale,
    });
  } catch (e) {
    /* Silently catch the error */
  }

  const namespacesRequired = getNamespacesFromLocale(locale);

  const i18nProps = await serverSideTranslations(
    i18nLocaleFormatter(locale) || ENGLISH_LOCALE_IDENTIFIER,
    namespacesRequired
  );
  return {
    props: {
      page,
      announcementBar,
      ...i18nProps,
    },
  };
};

export const getStaticPaths = async () => {
  let allPages: PrismicDocument[][] = [];

  try {
    allPages = await Promise.all(
      LOCALES.map((locale) =>
        createPrismicClient().getAllByType('page', { lang: locale })
      )
    );
  } catch (error) {
    captureException(error);
    throw new Error('Failed to fetch Prismic pages during static generation');
  }

  const pagesWithUid = allPages.flat().filter((page) => !!page.uid);

  return {
    paths: pagesWithUid.map((page) => ({
      params: { uid: getPathSegmentsFromPageDoc(page) },
      locale: page.lang,
    })),
    fallback: false,
  };
};
